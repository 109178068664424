@import "./colors.scss";
@mixin Table {
  table {
    color: white;
    table-layout: auto;
    width: 100%;

    tr:nth-child(odd) {
      background-color: $gray800;
    }

    th {
      font-weight: bold;
      text-decoration: underline;
      text-align: left;
      padding: 10px;
    }

    td {
      padding: 10px;
    }
  }
}
