.Inputs {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .Body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
