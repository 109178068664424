@import "../../styles/colors.scss";

span.At {
  color: $gray100;
  opacity: 0.7;
}

.DescribeRecipe {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  img {
    height: 20px;
    width: 20px;
  }
}

.RowBody {
  display: inherit;
  align-items: center;
  flex-grow: 1;
  margin-left: 8px;
  span {
    margin-left: 3px;
  }
}
