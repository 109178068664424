.ListResourceNodes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  justify-content: flex-start;
}

.ResourceNodeItem {
  position: relative;

  button {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}
