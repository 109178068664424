@import "../../../../styles/colors.scss";
@import "../../../../styles/styles.scss";
@import "../../../../styles/fonts.scss";

.Node {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    width: 25px;
    height: 25px;
    margin-left: 7px;
  }

  .NodeBody {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: $gray800;
    padding: 10px;
    flex-grow: 1;

    .ResourceType {
      font-size: 1.2em;
      color: darken(white, 10%);
      font-weight: bolder;
    }

    .InputConfiguration {
      display: flex;
      flex-direction: row;

      .ManySelectContainer,
      .NumberInput {
        width: 50%;
        flex-grow: 1;
      }
    }

    .NodeCounts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      background-color: $gray600;

      .Count {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        display: flex;
        justify-items: center;
        user-select: none;

        .Purity {
          font-size: 0.85em;
          color: $gray50;
          margin-bottom: 3px;
        }

        label {
          margin: 7px;
        }

        .Buttons {
          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            background-color: $orange;
            border: none;
            color: white;
            width: 20px;
            height: 20px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
              background-color: $gray300;
              opacity: 0.2;
            }
          }
        }
      }
    }
  }
}
