@import "./styles/colors.scss";

.Header {
  width: 100%;
  padding: 25px;
  background-color: $gray800;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .Logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    color: $gray50;
    opacity: 0.9;
    flex-grow: 1;

    h1 {
      font-size: 1.9rem;
      margin-left: 10px;
      height: fit-content;
      letter-spacing: -1px;
    }

    img {
      max-height: 40px;
    }
  }
}

.Footer {
  width: 100%;
  padding: 20px;

  .TM {
    opacity: 0.7;
    font-size: 13px;
    max-width: 700px;
  }
}

.App {
  min-height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .Header .Logo {
    flex-direction: column;
  }
}
