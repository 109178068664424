$orange: #c4773d;

$gray900: #191919;
$gray800: #292929;
$gray700: #393939;
$gray600: #494949;
$gray500: #636363;
$gray400: #737373;
$gray300: #828282;
$gray200: #919191;
$gray100: #a0a0a0;
$gray50: #afafaf;
