@import "../../styles/colors.scss";

.PercentBar {
  // min-width: 100%;
  background-color: $gray400;
  height: 10px;
  position: relative;

  .PercentBarFill {
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $orange;
  }
}
