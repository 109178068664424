.OutputRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .NumberInput {
    flex-grow: 1;
  }

  img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
}
