@import "../../styles/colors.scss";

.ManySelectContainer {
  position: relative;

  .ManySelect {
    height: 100%;
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.4);
    background-color: $gray600;
    border-color: rgba(255, 255, 255, 0.1);
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 35px;

    .ManySelectBody {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      white-space: nowrap;
      overflow-x: hidden;
      height: 100%;
      padding: 10px;

      p {
        height: 100%;
      }
    }

    svg {
      min-width: 30px;
    }

    label {
      text-shadow: none;
      font-size: 12px;
      font-weight: normal;
      height: 16px;
      line-height: 16px;
      opacity: 0.6;
      margin-bottom: 3px;
    }

    &:hover {
      color: $orange;
      border-color: rgba($orange, 0.7);
      border-bottom-width: 2px;
      border-bottom-color: $orange;
      text-shadow: 0 0 4px rgba($orange, 0.2);
    }
  }

  .ManySelectDropDown {
    position: absolute;
    top: 100%;
    left: 0;
    text-shadow: 0 0 25px $gray700;
    background-color: $gray700;
    border-color: $gray700;
    width: 100%;
    margin-top: 1px;
    padding: 15px 15px;
    z-index: 150;
    overflow-y: scroll;
    box-sizing: border-box;

    hr {
      opacity: 0.2;
      border-width: 1px;
      margin-top: 15px;
    }

    .CheckboxContainer .CheckboxSelection {
      margin: 0.25em;
      margin-right: 0.35em;
    }
  }

  .ManySelectDropDown .Option {
    user-select: none;

    &:not(:first-child) {
      margin-top: 15px;
    }

    &.selected {
      color: $orange;
    }
  }
}
