@import "../../styles/colors.scss";

.NumberInput {
  background-color: $gray600;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;

  .ActionButtons {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;

    button {
      background-color: rgba($orange, 0.7);
      border: none;
      color: white;
      padding: 4px 7px;
      font-size: 14px;

      &:not(:first-of-type) {
        margin-left: 3px;
      }
    }
  }

  .NumberInputBody {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background-color: $gray600;
    color: white;
    text-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
    border: none;
    outline: none;
    flex-grow: 1;
    width: 70px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    line-height: 16px;
    opacity: 0.6;
    user-select: none;
    margin-bottom: 3px;
  }
}
