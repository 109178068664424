@import "../../../styles/colors.scss";

.Preview {
  padding: 10px;
  width: 100%;

  .Head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Body {
    justify-content: center;
    align-items: center;
  }

  ul {
    padding-left: 24px;
    list-style: none;

    li {
      background-color: $gray700;
      padding: 3px 5px;
      margin-top: 4px;
    }
  }
}
