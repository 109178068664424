@import "../../styles/colors.scss";
@import "../../styles/zindex.scss";
$spacing: 5px;

.Designer-- {
  display: flex;
  max-width: 100vw;
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  padding: $spacing;
  box-sizing: border-box;
  background-color: $gray900;
  justify-content: space-between;
  gap: ($spacing * 3);

  .Center-- {
    display: flex;
    flex-direction: column;
  }

  .Inputs-Container,
  .Outputs-Container,
  .Preview-Container,
  .DesignConfig-Container {
    background-color: rgba($gray800, 0.4);
  }

  .Inputs-Container,
  .Outputs-Container,
  .Center-- {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    width: 100%;
  }

  .Preview-Container {
    flex-grow: 1;
  }
}

@media (max-width: 600px) {
  .Designer-- {
    flex-direction: column;
  }
}
