@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
.Header{width:100%;padding:25px;background-color:#292929;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.Header .Logo{display:flex;flex-direction:row;align-items:center;text-transform:uppercase;color:#afafaf;opacity:0.9;flex-grow:1}.Header .Logo h1{font-size:1.9rem;margin-left:10px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;letter-spacing:-1px}.Header .Logo img{max-height:40px}.Footer{width:100%;padding:20px}.Footer .TM{opacity:0.7;font-size:13px;max-width:700px}.App{min-height:100%;width:100vw;display:flex;flex-direction:column;flex-grow:1;overflow-x:hidden}#root{overflow-x:hidden;min-height:100vh;display:flex;flex-direction:column}@media (max-width: 600px){.Header .Logo{flex-direction:column}}

.FactoryDesigner{display:flex;flex-direction:row;justify-content:center;flex-grow:1;padding:10px}.FactoryDesigner .Designer,.FactoryDesigner .Preview{align-items:stretch;padding:10px}.FactoryDesigner .Designer{display:flex;flex-grow:1;flex-direction:column;width:100%;min-height:100%;background-color:#292929}.FactoryDesigner .Preview{width:50%;height:100%;flex-grow:1;min-height:100%}

.Foundation{background-color:"lightgrey"}.Piece *{-webkit-user-select:none;-ms-user-select:none;user-select:none}

.DesignContainer{display:flex;flex-direction:column;flex-grow:1;position:relative;width:100%;overflow:auto}.Design{position:absolute;overflow:hidden;width:10000px;height:10000px;background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSIzNCIgaGVpZ2h0PSIzNCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDguNSBMIDM0IDguNSBNIDguNSAwIEwgOC41IDM0IE0gMCAxNyBMIDM0IDE3IE0gMTcgMCBMIDE3IDM0IE0gMCAyNS41IEwgMzQgMjUuNSBNIDI1LjUgMCBMIDI1LjUgMzQiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDM0IDAgTCAwIDAgMCAzNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=")}

.CheckboxContinaer{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:block;position:relative;-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}.CheckboxContinaer .Checkbox{width:100%;display:flex;flex-direction:row;align-items:center;justify-items:center}.CheckboxContinaer .Checkbox .Label{position:relative;margin-left:10px;cursor:pointer}.CheckboxContinaer .Checkbox .Checkmark{flex-shrink:0;height:15px;width:15px;display:flex;justify-content:center;flex-direction:row;background-color:transparent;border:#919191 2px solid}.CheckboxContinaer .Checkbox .Checkmark::after{content:"";position:absolute;display:none}.CheckboxContinaer .Checkbox input[type="checkbox"]{cursor:pointer;position:absolute;opacity:0;height:0;width:0}.CheckboxContinaer .Checkbox input.CheckboxInput{cursor:pointer;position:absolute;opacity:0;height:0;width:0}.CheckboxContinaer .Checkbox.CheckboxIntermediate .Checkmark::after{margin-top:4px;width:7px;height:3px;background-color:white;transform:rotate(0deg)}.CheckboxContinaer .Checkbox .Checkmark::after{width:5px;height:10px;border:solid white;border-width:0 3px 3px 0;transform:rotate(45deg)}.CheckboxContinaer .Checkbox:hover input[type="checkbox"] ~ .Checkmark{background-color:#919191;border-color:#919191}.CheckboxContinaer .Checkbox input[type="checkbox"]:checked ~ .Checkmark{background-color:#c4773d;border-color:#c4773d}.CheckboxContinaer .Checkbox input[type="checkbox"]:checked ~ .Label{color:#c4773d}.CheckboxContinaer .Checkbox.CheckboxIntermediate input[type="checkbox"]:checked ~ .Checkmark{background-color:#919191;border-color:#919191}.CheckboxContinaer .Checkbox.CheckboxIntermediate input[type="checkbox"]:checked ~ .Label{color:white}.CheckboxContinaer .Checkbox input[type="checkbox"]:checked ~ .Checkmark::after{display:block}

.NumberInput{background-color:#494949;min-height:50px;display:flex;flex-direction:column;padding:10px;justify-content:center}.NumberInput .ActionButtons{display:flex;flex-direction:row;flex-grow:1;justify-content:flex-end}.NumberInput .ActionButtons button{background-color:rgba(196,119,61,0.7);border:none;color:white;padding:4px 7px;font-size:14px}.NumberInput .ActionButtons button:not(:first-of-type){margin-left:3px}.NumberInput .NumberInputBody{align-items:center;display:flex;flex-direction:row;flex-grow:1}.NumberInput input{-webkit-appearance:none;-moz-appearance:textfield;background-color:#494949;color:white;text-shadow:0 0 25px rgba(255,255,255,0.5);border:none;outline:none;flex-grow:1;width:70px}.NumberInput input::-webkit-outer-spin-button,.NumberInput input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.NumberInput label{font-size:12px;font-weight:normal;height:16px;line-height:16px;opacity:0.6;-webkit-user-select:none;-ms-user-select:none;user-select:none;margin-bottom:3px}

.ManySelectContainer{position:relative}.ManySelectContainer .ManySelect{height:100%;text-shadow:0 0 4px rgba(255,255,255,0.4);background-color:#494949;border-color:rgba(255,255,255,0.1);-webkit-user-select:none;-ms-user-select:none;user-select:none;display:flex;flex-direction:row;align-items:center;min-height:35px}.ManySelectContainer .ManySelect .ManySelectBody{display:flex;flex-direction:column;justify-content:center;flex-grow:1;white-space:nowrap;overflow-x:hidden;height:100%;padding:10px}.ManySelectContainer .ManySelect .ManySelectBody p{height:100%}.ManySelectContainer .ManySelect svg{min-width:30px}.ManySelectContainer .ManySelect label{text-shadow:none;font-size:12px;font-weight:normal;height:16px;line-height:16px;opacity:0.6;margin-bottom:3px}.ManySelectContainer .ManySelect:hover{color:#c4773d;border-color:rgba(196,119,61,0.7);border-bottom-width:2px;border-bottom-color:#c4773d;text-shadow:0 0 4px rgba(196,119,61,0.2)}.ManySelectContainer .ManySelectDropDown{position:absolute;top:100%;left:0;text-shadow:0 0 25px #393939;background-color:#393939;border-color:#393939;width:100%;margin-top:1px;padding:15px 15px;z-index:150;overflow-y:scroll;box-sizing:border-box}.ManySelectContainer .ManySelectDropDown hr{opacity:0.2;border-width:1px;margin-top:15px}.ManySelectContainer .ManySelectDropDown .CheckboxContainer .CheckboxSelection{margin:0.25em;margin-right:0.35em}.ManySelectContainer .ManySelectDropDown .Option{-webkit-user-select:none;-ms-user-select:none;user-select:none}.ManySelectContainer .ManySelectDropDown .Option:not(:first-child){margin-top:15px}.ManySelectContainer .ManySelectDropDown .Option.selected{color:#c4773d}

.WithPopover{display:flex;flex-direction:row;justify-content:space-between;align-items:center;position:relative}.WithPopover .Content{flex-grow:1}.Popover{-webkit-user-select:none;-ms-user-select:none;user-select:none;background-color:#393939;opacity:1;padding:5px 10px;position:absolute;z-index:10;font-size:14px;box-shadow:0 0 10px 2px rgba(0,0,0,0.7);color:white;font-weight:normal;font-size:14px;top:100%;display:flex;flex-direction:column;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.Popover p:not(:first-child){margin-top:10px}

.Belt{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:flex}

span.At{color:#a0a0a0;opacity:0.7}.DescribeRecipe{display:flex;flex-direction:row;justify-content:space-between}.DescribeRecipe img{height:20px;width:20px}.RowBody{display:inherit;align-items:center;flex-grow:1;margin-left:8px}.RowBody span{margin-left:3px}

.TreeView{padding-right:25px}.TreeView li{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.TreeView li.finished{opacity:0.25}


.ModalContainer{width:100vw;height:100vh;position:fixed;top:0;left:0;background-color:rgba(25,25,25,0.7);z-index:20;display:flex;align-items:center;justify-content:center}.ModalContainer .Modal{position:relative;min-width:200px;min-height:150px;background-color:#292929;padding:20px;opacity:1}.ModalContainer .Modal .ModalHeader{display:flex;flex-direction:row;justify-content:space-between}.ModalContainer .Modal .ModalHeader button{background-color:transparent;color:white;border:none}.ModalContainer .Modal .ModalHeader button:hover{background-color:rgba(115,115,115,0.5)}

.WithInfo{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.WithInfo .Content{flex-grow:1}.InfoButton{position:relative}.InfoButton svg{opacity:0.7}.InfoButton svg:hover{opacity:1}.InfoButton .InfoPopover{-webkit-user-select:none;-ms-user-select:none;user-select:none;background-color:#393939;opacity:1;padding:5px 10px;position:absolute;z-index:10;font-size:14px;box-shadow:0 0 10px 2px rgba(0,0,0,0.7);color:white;font-weight:normal;font-size:14px}.InfoButton .InfoPopover p:not(:first-child){margin-top:10px}

.DesignConfiguration{width:100%;padding:10px}.DesignConfiguration .Body{justify-content:center;align-items:center}

.ListResourceNodes{display:flex;flex-direction:column;flex-wrap:wrap;margin:auto;justify-content:flex-start}.ResourceNodeItem{position:relative}.ResourceNodeItem button{position:absolute;top:2px;right:2px}

.Button.Primary{background-color:#c4773d;border:none;color:white;padding:3px 7px}.Button.Primary:disabled{background-color:#636363;opacity:0.3}a{text-decoration:none}body{font-family:"Open Sans", sans-serif}.Warn{color:yellow}.Info{opacity:0.5;text-transform:uppercase;font-size:14px;-webkit-user-select:none;-ms-user-select:none;user-select:none}.BoxLabel{text-shadow:none;font-size:12px;font-weight:normal;height:16px;line-height:16px;color:rgba(175,175,175,0.7)}.Highlight{color:#c4773d;font-weight:bold}p,h1,h2,h3,h4,h5,h6,span{margin:0;padding:0}.Node{display:flex;flex-direction:row;align-items:center}.Node span{display:flex;flex-direction:row;align-items:center}.Node img{width:25px;height:25px;margin-left:7px}.Node .NodeBody{display:flex;flex-direction:column;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;background-color:#292929;padding:10px;flex-grow:1}.Node .NodeBody .ResourceType{font-size:1.2em;color:#e6e6e6;font-weight:bolder}.Node .NodeBody .InputConfiguration{display:flex;flex-direction:row}.Node .NodeBody .InputConfiguration .ManySelectContainer,.Node .NodeBody .InputConfiguration .NumberInput{width:50%;flex-grow:1}.Node .NodeBody .NodeCounts{display:flex;flex-direction:row;justify-content:space-between;flex-grow:1;background-color:#494949}.Node .NodeBody .NodeCounts .Count{padding:10px 15px;display:flex;flex-direction:column;display:flex;justify-items:center;-webkit-user-select:none;-ms-user-select:none;user-select:none}.Node .NodeBody .NodeCounts .Count .Purity{font-size:0.85em;color:#afafaf;margin-bottom:3px}.Node .NodeBody .NodeCounts .Count label{margin:7px}.Node .NodeBody .NodeCounts .Count .Buttons{display:flex;flex-direction:row;align-items:center}.Node .NodeBody .NodeCounts .Count .Buttons button{background-color:#c4773d;border:none;color:white;width:20px;height:20px;padding:0;display:flex;align-items:center;justify-content:center}.Node .NodeBody .NodeCounts .Count .Buttons button:disabled{background-color:#828282;opacity:0.2}

.Inputs{width:100%;padding:10px;display:flex;flex-direction:column}.Inputs .Body{display:flex;flex-direction:column;flex-grow:1}

.PercentBar{background-color:#737373;height:10px;position:relative}.PercentBar .PercentBarFill{height:10px;position:absolute;top:0;left:0;background-color:#c4773d}

.RawInputTable table{color:white;table-layout:auto;width:100%}.RawInputTable table tr:nth-child(odd){background-color:#292929}.RawInputTable table th{font-weight:bold;text-decoration:underline;text-align:left;padding:10px}.RawInputTable table td{padding:10px}

.SolveInput{display:flex;flex-direction:row;justify-content:space-between;align-items:center}

.Outputs{padding:10px;width:100%}.Outputs .Body{justify-content:center;align-items:center}

.OutputRow{width:100%;display:flex;flex-direction:row;align-items:center}.OutputRow .NumberInput{flex-grow:1}.OutputRow img{height:40px;width:40px;margin-right:10px}

.ProductionTable table{color:white;table-layout:auto;width:100%}.ProductionTable table tr:nth-child(odd){background-color:#292929}.ProductionTable table th{font-weight:bold;text-decoration:underline;text-align:left;padding:10px}.ProductionTable table td{padding:10px}.ProductionTable .Link{text-decoration:underline;color:#d09264;cursor:pointer}

.ProductionTable .Link,.ProductionTable .EditPencil{text-decoration:underline;color:#d09264;cursor:pointer}.ProductionTable .EditRow{display:flex;flex-direction:row;justify-content:space-between}

.Designer--{display:flex;max-width:100vw;height:100%;flex-grow:1;flex-direction:row;padding:5px;box-sizing:border-box;background-color:#191919;justify-content:space-between;grid-gap:15px;gap:15px}.Designer-- .Center--{display:flex;flex-direction:column}.Designer-- .Inputs-Container,.Designer-- .Outputs-Container,.Designer-- .Preview-Container,.Designer-- .DesignConfig-Container{background-color:rgba(41,41,41,0.4)}.Designer-- .Inputs-Container,.Designer-- .Outputs-Container,.Designer-- .Center--{display:flex;flex-direction:column;flex-grow:1;min-height:100%;width:100%}.Designer-- .Preview-Container{flex-grow:1}@media (max-width: 600px){.Designer--{flex-direction:column}}

.Preview{padding:10px;width:100%}.Preview .Head{display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%}.Preview .Body{justify-content:center;align-items:center}.Preview ul{padding-left:24px;list-style:none}.Preview ul li{background-color:#393939;padding:3px 5px;margin-top:4px}

body{font-family:"Open Sans", sans-serif}.Warn{color:yellow}.Info{opacity:0.5;text-transform:uppercase;font-size:14px;-webkit-user-select:none;-ms-user-select:none;user-select:none}.BoxLabel{text-shadow:none;font-size:12px;font-weight:normal;height:16px;line-height:16px;color:rgba(175,175,175,0.7)}.Highlight{color:#c4773d;font-weight:bold}p,h1,h2,h3,h4,h5,h6,span{margin:0;padding:0}

body{padding:0;margin:0;background-color:#1c1a1a;color:#eeefee;font-family:"Roboto"}h1,h2,h3,h4,h5,h6{padding:0;margin:0}h2{font-weight:normal}button{-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}*::-webkit-scrollbar{width:6px}*::-webkit-scrollbar-track{background:rgba(0,0,0,0.0005)}*::-webkit-scrollbar-thumb{background-color:rgba(255,255,255,0.05);border-radius:10px}*::-webkit-scrollbar-corner{background-color:rgba(0,0,0,0.025)}

html{box-sizing:content-box}*,*:before,*:after{box-sizing:border-box}

.Button.Primary{background-color:#c4773d;border:none;color:white;padding:3px 7px}.Button.Primary:disabled{background-color:#636363;opacity:0.3}a{text-decoration:none}

