@import "../../../../styles/table.scss";
@import "../../../../styles/colors.scss";

.ProductionTable {
  @include Table;

  .Link {
    text-decoration: underline;
    color: lighten($orange, 10%);
    cursor: pointer;
  }
}
