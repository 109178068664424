@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import "./colors.scss";

body {
  font-family: "Open Sans", sans-serif;
}

.Warn {
  color: yellow;
}

.Info {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 14px;
  user-select: none;
}

.BoxLabel {
  text-shadow: none;
  font-size: 12px;
  font-weight: normal;
  height: 16px;
  line-height: 16px;
  color: rgba($gray50, 0.7);
}

.Highlight {
  color: $orange;
  font-weight: bold;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  margin: 0;
  padding: 0;
}
